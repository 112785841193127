import Header from "components/Header";
import Layout from "components/Layout";
import React from "react";

const NotFoundPage = () => (
  <Layout>
    <Header title="Not Found">Well, I don't know how you came here but... welcome!</Header>
  </Layout>
);

export default NotFoundPage;
